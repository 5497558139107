import useSWR, { useSWRConfig } from "swr";
import axios from "axios";

const path = "/partner";

const usePartners = () => {
  const { data: partners, error } = useSWR(path, axios.get);
  const { mutate } = useSWRConfig();

  const addPartner = async (partner) => {
    const addedPartner = await axios.post(path, partner);
    mutate(path);
    return addedPartner;
  };

  const deletePartner = async (partnerId) => {
    const deletedPartner = await axios.delete(`${path}/${partnerId}`);
    mutate(path);
    return deletedPartner;
  };

  const updatePartner = async (partnerId, updatePartnerDto) => {
    const updatedPartner = await axios.put(
      `${path}/${partnerId}`,
      updatePartnerDto
    );
    mutate(path);
    mutate(`/partner/${partnerId}`)
    return updatedPartner;
  };

  const uploadLogo = async (image) => {
    const formData = new FormData();
    formData.append("logo", image);
    const { logoUrl } = await axios.post(`/partner/logo`, formData);
    return logoUrl;
  };

  return {
    partners,
    isLoading: !error && !partners,
    addPartner,
    deletePartner,
    updatePartner,
    uploadLogo,
  };
};

export default usePartners;
