import React, { useState } from "react";
import { Table, PageHeader, Input, Button, message, Tag, Modal } from "antd";
import Container from "../../../../common/components/Container/Container";
import {
  PlusOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styles from "./Partners.module.scss";
import usePartners from "../../hooks/usePartners";
import waitTime from "../../../../common/utils/waitTime";
import dayjs from "dayjs";

const { confirm } = Modal;

const Partners = () => {
  const { partners, isLoading, deletePartner } = usePartners();
  const history = useHistory();

  const columns = [
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (dateCreated) => {
        return dayjs.utc(dateCreated).local().format("MM/DD/YYYY h:mm A");
      },
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Action",
      key: "action",
      render: (_, partner) => {
        return (
          <DeleteOutlined
            style={{ color: "#ff4d4f" }}
            onClick={(e) => {
              e.stopPropagation();
              deletePartnerConfirm(partner.id);
            }}
            // className={styles.deleteIcon}
          />
        );
      },
    },
  ];

  const deletePartnerConfirm = (partnerId) => {
    confirm({
      title: `Confirm Delete`,
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to delete this report?`,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await waitTime(2000);
          await deletePartner(partnerId);
          message.success("Partner sucessfully deleted!");
        } catch (err) {
          message.error("There was a problem trying to delete the partner");
        }
      },
    });
  };

  return (
    <>
      <Container noPadding>
        <PageHeader
          title="Partners"
          className="site-page-header"
          extra={[
            <Link to="/partner/add">
              <Button type="primary" icon={<PlusOutlined />}>
                Create Partner
              </Button>
            </Link>,
          ]}
        />
      </Container>

      <Container>
        <Table
          rowKey={(partner) => partner.id}
          loading={isLoading}
          columns={columns}
          dataSource={partners}
          rowClassName={styles.tableRow}
          onRow={(partner) => {
            return {
              onClick: (e) => {
                e.stopPropagation();
                history.push(`/partner/${partner.id}`);
              },
            };
          }}
        />
      </Container>
    </>
  );
};

export default Partners;
