import React, { useState, useEffect } from "react";
import { Form, Button, Select, Space, DatePicker, Input, Upload } from "antd";
import { Link } from "react-router-dom";
import useIntegrationUsers from "../../../competitions/hooks/useIntegrationUsers";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;
const { RangePicker } = DatePicker;

const sexes = ["Male", "Female", "Unspecified"];

const AddPartnerForm = ({ isLoading, onNextStep }) => {
  const [form] = Form.useForm();
  const { integrationUsers } = useIntegrationUsers();
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (integrationUsers) form.setFieldsValue({ integrationUserId: null });
  }, [integrationUsers]);

  const handleFormFinish = (values) => {
    const {
      name,
      description,
      integrationUserId,
      learnMoreUrl,
      signupUrl,
      logo,
    } = values;

    const partner = {
      name,
      description,
      integrationUserId,
      learnMoreUrl,
      signupUrl,
    };
    onNextStep(partner, logo);
  };

  const getFile = (e) => {
    if (Array.isArray(e)) return e;
    return e && e.fileList;
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const uploadButton = (
    <div>
      {<PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <Form layout={"vertical"} onFinish={handleFormFinish} form={form}>
      <Form.Item
        name="name"
        label="Name"
        rules={[
          {
            required: true,
            message: "Name is required",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Integration User" name="integrationUserId">
        <Select
          showSearch
          defaultActiveFirstOption={false}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value={null}>None</Option>
          {integrationUsers?.map((integrationUser) => (
            <Option
              key={integrationUser.id}
              value={integrationUser.id}
            >{`${integrationUser.companyName}`}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="logo"
        label="Logo"
        valuePropName="fileList"
        getValueFromEvent={getFile}
      >
        <Upload
          listType="picture-card"
          getValueFromEvent={getFile}
          onChange={handleChange}
          beforeUpload={(file) => false}
          showUploadList={{
            showPreviewIcon: false,
          }}
        >
          {fileList.length >= 1 ? null : uploadButton}
        </Upload>
      </Form.Item>
      <Form.Item
        name="description"
        label="Description"
        rules={[{ required: true, message: "Description is required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="learnMoreUrl"
        label="Learn More URL"
        rules={[{ required: true, message: "Learn More URL is required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="signupUrl" label="Sign Up URL">
        <Input />
      </Form.Item>
      <Form.Item>
        <Space>
          <Link to="/partner">
            <Button>Cancel</Button>
          </Link>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Next
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default AddPartnerForm;
