import React, { useState } from "react";
import { Table, PageHeader, Input, Button, message, Tag, Modal } from "antd";
import Container from "../../../../common/components/Container/Container";
import {
  PlusOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import useCompetitions from "../../hooks/useCompetitions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styles from "./Competition.module.scss";
import waitTime from "../../../../common/utils/waitTime";
import dayjs from "dayjs";

const { confirm } = Modal;

const Competitions = () => {
  const { competitions, isLoading, deleteCompetition } = useCompetitions();
  const history = useHistory();

  const columns = [
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (dateCreated) => {
        return dayjs.utc(dateCreated).local().format("MM/DD/YYYY h:mm A");
      },
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => {
        let color = null;
        if (status === "Active") color = "green";
        else if (status === "Upcoming") color = "blue";
        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, competition) => {
        return (
          <DeleteOutlined
            style={{ color: "#ff4d4f" }}
            onClick={(e) => {
              e.stopPropagation();
              deleteCompetitionConfirm(competition.id);
            }}
            // className={styles.deleteIcon}
          />
        );
      },
    },
  ];

  const deleteCompetitionConfirm = (competitionId) => {
    confirm({
      title: `Confirm Delete`,
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to delete this report?`,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await waitTime(2000);
          await deleteCompetition(competitionId);
          message.success("Competition sucessfully deleted!");
        } catch (err) {
          message.error("There was a problem trying to delete the competition");
        }
      },
    });
  };

  return (
    <>
      <Container noPadding>
        <PageHeader
          title="Competitions"
          className="site-page-header"
          extra={[
            <Link to="/competitions/add">
              <Button type="primary" icon={<PlusOutlined />}>
                Create Competition
              </Button>
            </Link>,
          ]}
        />
      </Container>

      <Container>
        <Table
          rowKey={(competition) => competition.id}
          loading={isLoading}
          columns={columns}
          dataSource={competitions}
          rowClassName={styles.tableRow}
          onRow={(competition) => {
            return {
              onClick: (e) => {
                e.stopPropagation();
                history.push(`/competitions/${competition.id}`);
              },
            };
          }}
        />
      </Container>
    </>
  );
};

export default Competitions;
